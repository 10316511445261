import {
  IUser,
  UserRole,
} from 'interfaces';
import pick from 'lodash/pick';

const userFieldsToStore = [
  "id",
  "first_name",
  "last_name",
  "role",
  "email",
  "phone",
  "email_verified_at",
  "address",
  "locationNote",
  "operationHours",
  "default_address_name",
  "default_address_id",
  "roles",
  "payout",
  "country"
];

export const daysOfWeek = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

export const getUserForStorage = (user: IUser) => {
  console.log("saving to the local", user);
  const userToStore = pick(user, userFieldsToStore);
  localStorage.setItem("user@store", JSON.stringify(userToStore));
  return user;
};

export const isCustomer = () => {
  return (
    JSON.parse(localStorage.getItem("user@store") || "{}").role ===
    UserRole.CUSTOMER
  );
};

export const isHolder = () => {
  return (
    JSON.parse(localStorage.getItem("user@store") || "{}").role ===
    UserRole.HOLDER
  );
  // const user: IUser = JSON.parse(localStorage.getItem("user@store") || "{}");
  // const roles = Array.isArray(user.roles) ? user.roles : [];
  // return (roles.includes(UserRole.HOLDER));
};


export const hasMultiRoles = () => {
  const user: IUser = JSON.parse(localStorage.getItem("user@store") || "{}");
  const roles = Array.isArray(user.roles) ? user.roles : [];
  return roles.length > 1;
};

export const isLoggedIn = () => {
  const json = JSON.parse(localStorage.getItem("user@store") || "{}");
  return json !== undefined && json.id !== undefined;
};

export const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user@store") || "{}") as IUser;
};

export const isHolderUser = (user: IUser) => {
  let roles: string[] = user.roles;
  roles = Array.isArray(roles) ? roles : [];
  return (
    roles.includes(UserRole.HOLDER)
  );
};


export const hasAccount = (user: IUser) => {
  return (isHolderUser(user) && user && user.payout && user.payout.account)
};

export const verifyAccount = (user: IUser) => {
  return (hasAccount(user) && user.payout!.status === 'completed');
};

export const aliasName = (user: IUser): string => {
  if(!user){
    return '';
  }
  return `${user.first_name} ${Array.from(user.last_name)[0]}`;
}