import * as React from "react";
import { useEffect, useState } from "react";

import { NavLink } from "react-router-dom";

import MenuIcon from "@mui/icons-material/Menu";
import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useTheme } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import routes from "../../routes";
import { getCurrentUser, hasMultiRoles, isCustomer, isHolder, isLoggedIn } from "../../utils";
import Divider from "@mui/material/Divider";
import CheckIcon from '@mui/icons-material/Check';

function NavBar() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 380);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const addressStyle: React.CSSProperties = {
    fontSize: isSmallScreen ? "10px" : "14px",
    marginRight: isSmallScreen ? "-6px" : "0px",
    fontFamily: "Rubik, sans-serif",
  };

  const imageStyle: React.CSSProperties = {
    top: isSmallScreen ? "7px" : "3px",
    marginLeft: isSmallScreen ? "90px" : "150px",
    height: "auto",
  };

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );
  
  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const { first_name: firstName, last_name: lastName } = JSON.parse(
    localStorage.getItem("user@store") || "{}"
  );
  const initials = firstName?.charAt(0) + lastName?.charAt(0) || "";

  useEffect(() => {
    console.log("isLoggedIn:", isLoggedIn());

    function handleResize() {
      setIsMobile(window.innerWidth < 450);
    }

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [location, setLocation] = useState<{
    latitude: number;
    longitude: number;
  } | null>(null);
  const [address, setAddress] = useState<string | null>(null);
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLocation({ latitude, longitude });
          const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
          const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;
          fetch(apiUrl)
            .then((response) => response.json())
            .then((data) => {
              if (data.results && data.results.length > 0) {
                const streetAddress = extractStreetAddress(
                  data.results[0].address_components
                );
                setAddress(streetAddress);
              } else {
                setAddress("Address not found");
              }
            })
            .catch((error) => {
              console.error("Error fetching address:", error);
              setAddress("Error fetching address");
            });
        },
        (error) => {
          console.error("Error getting location:", error.message);
        }
      );
    } else {
      console.error("Geolocation is not supported by your browser.");
    }
  }, []);

  const extractStreetAddress = (addressComponents) => {
    const streetTypeRegex =
      /(Avenue|Street|Road|Boulevard|Lane|Drive|Court|Parkway|Place|Circle|Terrace|Alley|Square|Crescent|Highway|Southeast|Southwest|Northeast|Northwest)\b/gi;
    const streetTypeAbbreviations: Record<string, string> = {
      Avenue: "Ave",
      Street: "St",
      Road: "Rd",
      Boulevard: "Blvd",
      Lane: "Ln",
      Drive: "Dr",
      Court: "Ct",
      Parkway: "Pkwy",
      Place: "Pl",
      Circle: "Cir",
      Terrace: "Ter",
      Alley: "Aly",
      Square: "Sq",
      Crescent: "Cres",
      Highway: "Hwy",
      Southeast: "SE",
      Southwest: "SW",
      Northeast: "NE",
      Northwest: "NW",
    };
    const streetNumber = addressComponents.find((component) =>
      component.types.includes("street_number")
    );
    const route = addressComponents.find((component) =>
      component.types.includes("route")
    );

    if (streetNumber && route) {
      let abbreviatedRoute = route.long_name.replace(
        streetTypeRegex,
        (match: string) => streetTypeAbbreviations[match]
      );
      return `${streetNumber.long_name} ${abbreviatedRoute}`;
    }
    return "Street address not found";
  };

  const handleRoleSelect = (role: 'holder'| 'customer') => {
    let currentUser = getCurrentUser();
    if(currentUser.role === role){
      return;
    }

    currentUser.role = role;
    localStorage.setItem("user@store", JSON.stringify(currentUser));
    window.location.reload();
  };

  return (
    <AppBar
      position="sticky"
      sx={{ backgroundColor: isHolder() ? "#E5E5E5" : "#F3E6D4" }}
    >
      <Toolbar disableGutters>
        <Box sx={{ flexGrow: 1, display: "flex" }}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
            color="inherit"
            sx={{
              position: "absolute",
              top: 8,
              left: 0,
              zIndex: 1,
            }}
          >
            <MenuIcon />
          </IconButton>
          {isLoggedIn() && (
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: "flex",
              }}
            >
              {routes
                .filter((route) => route.showInMenu)
                .map((route) => (
                  <MenuItem key={route.path} onClick={handleCloseNavMenu}>
                    <NavLink
                      to={route.path}
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <Typography textAlign="center">{route.name}</Typography>
                    </NavLink>
                  </MenuItem>
                ))}
            </Menu>
          )}
        </Box>
        {isHolder() ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }}
          >
            <img
              src={`${process.env.PUBLIC_URL}/HolderHub.png`}
              alt="HolderHub Logo"
              style={{
                width: isMobile ? "120px" : "160px",
                height: "auto",
              }}
            />
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginLeft: isMobile ? "90px" : "124px",
              position: "absolute",
              top: 0,
              bottom: 0,
            }}
          >
            <img
              src={`${process.env.PUBLIC_URL}/logowithname.png`}
              alt="Small Logo"
              style={{
                position: "absolute",
                top: isMobile ? "8px" : "0px",
                width: isMobile ? "110px" : "160px",
                height: "auto",
              }}
            />
          </div>
        )}
        {isLoggedIn() && (
          <>
            {
              //   {!isHolder() && (
              // <Box sx={{ marginRight: "20px" }}>
              //    <Typography style={addressStyle} variant="body2" gutterBottom>
              //      Current Address:
              //     <br />
              //    <Typography style={addressStyle}>
              //        <b>{address}</b>
              //        {/*<b>{getCurrentUser().default_address_name || "n/a"}</b>*/}
              //      </Typography>
              //    </Typography>
              //  </Box>
              //)}
            }
            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar
                    alt={initials}
                    src="/static/images/avatar/2.jpg"
                    sx={{
                      backgroundColor: "#ff741e",
                      marginRight: "20px",
                      "@media (max-width: 440px)": {
                        marginRight: "10px",
                        marginLeft: "0px",
                      },
                    }}
                  >
                    {initials}
                  </Avatar>
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {hasMultiRoles() && [
                  <MenuItem key="roles-header" disabled>
                    <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                      Roles
                    </Typography>
                  </MenuItem>,

                  // Holder Option
                  <MenuItem key="holder" onClick={() => handleRoleSelect('holder')}>
                    <Typography variant="body1">Holder</Typography>
                    {!isCustomer() && <CheckIcon style={{ marginInlineStart: 10 }} />}
                  </MenuItem>,

                  // Customer Option
                  <MenuItem key="customer" onClick={() => handleRoleSelect('customer')}>
                    <Typography variant="body1">Customer</Typography>
                    {isCustomer() && <CheckIcon style={{ marginInlineStart: 10 }} />}
                  </MenuItem>,

                  <Divider key="divider" />,
                ]}
                {routes
                  .filter((route) => route.showInSettingsMenu)
                  .map((route) => (
                    <MenuItem key={route.path} onClick={handleCloseNavMenu}>
                      <NavLink
                        to={route.path}
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        <Typography textAlign="center">{route.name}</Typography>
                      </NavLink>
                    </MenuItem>
                  ))}
              </Menu>
            </Box>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
}
export default NavBar;
